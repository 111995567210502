<template>
  <div v-if="loading">
    <Loader />
  </div>
  <div class="card" v-if="!loading">
    <div class="card-header">
      <h3 class="card-title" style="font-size: 20px; font-weight: bold">
        คลัง :
      </h3>
      <div class="card-toolbar" style="font-size: 16px">
        งวดบัญชีที่ : 3 วันเริ่มงวด: 2022-02-01 00:00:00 วันสิ้นงวด: 2022-02-18
        23:59:00
      </div>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <table
          class="table table-hover table-rounded table-striped border gy-7 gs-7"
        >
          <thead>
            <tr
              style="width: 100%"
              class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200 mw-200px"
            >
              <th>รหัสสินค้า</th>
              <th>ชื่อสินค้า</th>
              <th>ราคาขายปลีก</th>
              <th>ราคาโปร</th>
              <th>ราคาขายส่ง</th>
              <th>คงเหลือ</th>
              <!-- <th></th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-if="dataItemsShow.length < 1">
              <td class="text-center" colspan="6">ไม่มีข้อมูล</td>
            </tr>
            <tr v-for="(item, index) in dataItemsShow" :key="index">
              <td>
                {{
                  item.product.product_code ? item.product.product_code : "-"
                }}
              </td>
              <td>
                {{
                  item.product.product_name ? item.product.product_name : "-"
                }}
              </td>
              <td>
                {{
                  item.product.product_retail_price
                    ? item.product.product_retail_price
                    : "-"
                }}
              </td>
              <td>
                {{
                  item.product.product_promotion_price
                    ? item.product.product_promotion_price
                    : "-"
                }}
              </td>
              <td>
                {{
                  item.product.product_wholesale_price
                    ? item.product.product_wholesale_price
                    : "-"
                }}
              </td>
              <!-- <td>
                {{
                  item.product.product_bring_qty
                    ? item.product.product_bring_qty
                    : "-"
                }}
              </td> -->
              <td>
                {{ item.totalAmt ? item.totalAmt : item.item_amt }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- <DialogConfirmByPass
      :dialogConfirmByPass="dialogConfirmByPass"
      @submitConfirmPass="submitConfirmPass"
      @closeDialogConformByPass="closeDialogConformByPass"
    /> -->

    <div class="card-footer">
      <button
        type="button"
        class="btn btn-sm btn-light"
        style="font-size: 14px; font-weight: bold"
        @click="cancel"
      >
        กลับ
      </button>
      <Pagination
        :items="tableItems"
        @filterItems="filterItems"
        @handleChangePage="handleChangePage"
      />
    </div>
  </div>
</template>

<script>
import DialogConfirmByPass from "../../components/dialogConfirmByPass.vue";
import Pagination from "../../components/pagination.vue";
import Loader from "../../components/loaderIndex.vue";

import Swal from "sweetalert2/dist/sweetalert2.js";
import whApi from "@/api/warehouse/";

export default {
  components: {
    DialogConfirmByPass,
    Pagination,
    Loader,
  },
  setup() {
    document.title = "BELUCA | Report";
  },
  data: () => ({
    loading: false,
    loadingUpdate: false,
    dialogImportExcel: false,
    dialogConfirmByPass: false,

    showing1: null,

    hoverA: false,
    hoverB: false,

    dataItemsShow: [],
    tableItems: [],
    itemApprove: {},

    newProduct: [],

    newProductDub: [],
    newProductNonDub: [],

    productRemaining: 0,
  }),

  created() {
    this.getProductByWarehouse();
  },

  methods: {
    async getProductByWarehouse() {
      this.loading = true;
      let responseData = [];
      try {
        responseData = await whApi.cutOffStock.getAllItem(
          this.$route.query.warehouseId
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        // const newItem = this.setNewProduct(responseData.data);
        this.setNewProduct(responseData.data);
        this.handleProductDup(responseData.data);
        this.dataItemsShow = this.newProduct;
        this.tableItems = this.newProduct;
        this.productRemaining = responseData.product_remaining;
        this.loading = false;
        // if (method === 1) {
        //   await this.$router.push({
        //     query: {
        //       page: this.$route.query.page,
        //       get: 1,
        //     },
        //   });
        // }
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER.",
        });
      }
    },

    handleProductDup(item) {
      let amount = 0;
      let newId = "";
      let findIndexPd = "";

      item
        .map((v) => v.productId)
        .sort()
        .sort((a, b) => {
          if (a === b) {
            newId = a;
            amount = item
              .filter((data) => data.productId === a)
              .reduce((sumPrice, product) => sumPrice + product.item_amt, 0);
          } else {
          }
        });

      findIndexPd = this.newProduct.findIndex(
        (data) => data.productId === newId
      );

      this.newProduct[findIndexPd] = {
        ...this.newProduct[findIndexPd],
        totalAmt: amount,
      };
    },

    setNewProduct(item) {
      const unique = item
        .map((e) => e["productId"])
        .map((e, i, final) => final.indexOf(e) === i && i)
        .filter((e) => item[e])
        .map((e) => item[e]);

      this.newProduct = unique;

      // return unique;
    },

    //--- Pagination --- start
    filterItems(val) {
      this.dataItemsShow = val;
    },

    handleChangePage(val, showing1) {
      this.dataItemsShow = val;
      this.showing1 = showing1;
    },
    //--- Pagination --- end

    cancel() {
      this.$router.go(-1);
    },
    // goToNew() {
    //   this.$router.push("/warehouse/WH4/new");
    // },
    // goToEdit() {
    //   this.$router.push({
    //     name: "WH4-edit",
    //     query: {
    //       id: "123",
    //     },
    //   });
    // },
    // gotoImportExcel() {
    //   this.$router.push("/warehouse/WH4/importexcel");
    // },
    // goToDelete() {},
    // handleApprove() {
    //   this.dialogConfirmByPass = true;
    // },
    // submitConfirmPass() {
    //   this.isApprove = true;
    //   this.dialogConfirmByPass = false;
    // },
    // closeDialogConformByPass() {
    //   this.isApprove = false;
    //   this.dialogConfirmByPass = false;
    // },
  },
};
</script>
